import { logBi } from './actions/bi';
import { pubSubPublish } from './actions/pub-sub/publish';
import { createLogBiHandler } from './handlers/bi';
import { createPubSubPublishHandler } from './handlers/pub-sub/publish';
import { createHandler } from './lib';
import { logFedopsAction } from './actions/fedopsLogger';
import { openFullScreenOverlay, openMobileOverlay, } from './actions/openOverlay';
import { createVodBiLogger } from 'shared/bi/create-bi';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { createSetWidgetHeightHandler } from 'shared/worker/handlers/resize/set-widget-height';
import { resizeWindow } from 'shared/worker/actions/resize/resize-window';
import { createResizeWindowHandler } from 'shared/worker/handlers/resize/resize-window';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import { createResizeComponentHandler } from 'shared/worker/handlers/resize/resize-component';
import { createOpenOverlayHandlers } from './handlers/openOverlay';
import { performanceApplicationLoaded } from './actions/performanceLogger';
import { fitIntoView } from 'shared/worker/actions/fit-into-view';
import { createFitIntoViewHandler } from 'shared/worker/handlers/fit-into-view';
import { requestLogin } from 'shared/worker/actions/user/request-login';
import { createRequestLoginHandler } from 'shared/worker/handlers/user/login';
import { logout } from 'shared/worker/actions/user/logout';
import { createLogoutHandler } from 'shared/worker/handlers/user/logout';
import { getBiToken } from 'shared/worker/getBiToken';
function createBi(_a) {
    var platformAPIs = _a.platformAPIs, appParams = _a.appParams, compId = _a.compId;
    var _b = platformAPIs.bi, biToken = _b.biToken, visitorId = _b.visitorId;
    var instanceId = appParams.instanceId, instance = appParams.instance;
    return createVodBiLogger(platformAPIs.biLoggerFactory, {
        biToken: biToken || getBiToken(instance, platformAPIs),
        videoInstanceID: instanceId,
        compId: compId,
        visitorID: visitorId,
    });
}
export function createWorkerHandler(controllerConfig, _a) {
    var fedopsHandler = _a.fedopsHandler, handleSetWidgetHeight = _a.handleSetWidgetHeight, handleResizeWindow = _a.handleResizeWindow, handleResizeComponent = _a.handleResizeComponent, performanceApplicationLoadedHandler = _a.performanceApplicationLoadedHandler, handleFitIntoView = _a.handleFitIntoView, handleLogin = _a.handleLogin, handleLogout = _a.handleLogout;
    var platformAPIs = controllerConfig.platformAPIs;
    var pubSub = platformAPIs.pubSub;
    var bi = createBi(controllerConfig);
    var overlayHandlers = createOpenOverlayHandlers(controllerConfig);
    return createHandler([
        [logBi, createLogBiHandler(bi)],
        [pubSubPublish, createPubSubPublishHandler(pubSub)],
        [logFedopsAction, fedopsHandler],
        [setWidgetHeight, createSetWidgetHeightHandler(handleSetWidgetHeight)],
        [resizeWindow, createResizeWindowHandler(handleResizeWindow)],
        [resizeComponent, createResizeComponentHandler(handleResizeComponent)],
        [openFullScreenOverlay, overlayHandlers.openFullScreenOverlay],
        [openMobileOverlay, overlayHandlers.openMobileOverlay],
        [performanceApplicationLoaded, performanceApplicationLoadedHandler],
        [fitIntoView, createFitIntoViewHandler(handleFitIntoView)],
        [requestLogin, createRequestLoginHandler(handleLogin)],
        [logout, createLogoutHandler(handleLogout)],
    ]);
}
