import { createViewerScript } from 'shared/worker/viewerScript';
import { createViewerScriptConfigForOOI } from 'shared/worker/createHandlerForOOI';
import { createStore as createStoreWidget } from './widget.store';
import { createStore as createStoreMobile } from 'mobile/mobile.store';
import { setInitialState as setInitialStateDesktop } from 'widget/redux/initial-data-desktop';
import { setInitialState as setInitialStateMobile } from 'mobile/initial-data-mobile';
var getStoreBuilder = function (_a) {
    var isMobile = _a.isMobile;
    if (isMobile) {
        return {
            createStore: createStoreMobile,
            setInitialState: setInitialStateMobile,
        };
    }
    return {
        createStore: createStoreWidget,
        setInitialState: setInitialStateDesktop,
    };
};
var viewerScript = createViewerScript(createViewerScriptConfigForOOI, {
    getStoreBuilder: getStoreBuilder,
});
var createControllers = viewerScript.createControllers;
export { createControllers };
export default viewerScript;
