import { __awaiter, __generator } from "tslib";
import { AVAILABLE_LOCALES, DEFAULT_LOCALE, GET_PUBLIC_PATH, } from 'services/locale/config';
export var fetchTranslations = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
    var localeToFetch, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                localeToFetch = AVAILABLE_LOCALES.includes(locale)
                    ? locale
                    : DEFAULT_LOCALE;
                return [4 /*yield*/, fetch(GET_PUBLIC_PATH() + "static/locales/" + localeToFetch + ".json")];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
