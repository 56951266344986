import { __assign, __awaiter, __generator } from "tslib";
import _ from 'lodash';
/* global ENV_CONFIG */
import { BI_SOURCES } from '@wix/wix-vod-constants/dist/bi/sources';
import events from 'shared/bi/events';
import { playbackCorrelationChain } from 'shared/bi/correlation/playback';
import { logBI } from '@wix/wix-vod-shared/dist/src/common/logger';
export function createVodBiLogger(factory, defaultParams) {
    var _this = this;
    if (defaultParams === void 0) { defaultParams = {}; }
    var knownEvents = _.mapValues(events, function (evid) { return ({ evid: evid }); });
    var biFactory = factory({ endpoint: 'video' })
        .updateDefaults(__assign({ src: BI_SOURCES.VOD_SRC_ID }, defaultParams))
        .setEvents(knownEvents);
    biFactory.setMuted(ENV_CONFIG.disableBi);
    var logger = biFactory.logger();
    return {
        log: function (eventName, params) { return __awaiter(_this, void 0, void 0, function () {
            var evid, correlationId, event;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        evid = events[eventName];
                        correlationId = playbackCorrelationChain.getCorrelationId(eventName);
                        event = __assign(__assign({ evid: evid }, params), (correlationId && { correlationId: correlationId }));
                        if (ENV_CONFIG.logBiSend) {
                            logBI({ eventName: eventName, event: event });
                        }
                        return [4 /*yield*/, logger.log(event, { endpoint: 'video' })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    };
}
