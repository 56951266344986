import { __awaiter, __generator } from "tslib";
var getEmail = function (currentUser) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 5]);
                return [4 /*yield*/, currentUser.getEmail()];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_1 = _a.sent();
                if (!(global.Wix && global.Wix.currentMember)) return [3 /*break*/, 4];
                return [4 /*yield*/, new Promise(global.Wix.currentMember)];
            case 3: 
            /*
             *  TODO: wixCodeApi.user.currentUser.getEmail() is buggy;
             *  and throws an error if wixCodeApp isn't installed for a user
             *  that said this fallback allows to get user email at least in fs-modals/mobile overlays
             * */
            return [2 /*return*/, (_a.sent()).email];
            case 4: return [2 /*return*/, ' '];
            case 5: return [2 /*return*/];
        }
    });
}); };
export var getCurrentSiteUser = function (currentUser) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(currentUser && currentUser.loggedIn)) return [3 /*break*/, 2];
                _a = {
                    id: currentUser.id
                };
                return [4 /*yield*/, getEmail()];
            case 1: return [2 /*return*/, (_a.email = _b.sent(),
                    _a)];
            case 2: return [2 /*return*/, null];
        }
    });
}); };
