import DEVICE_TYPES from 'shared/constants/device-types';
import { getBiToken } from 'shared/worker/getBiToken';
var getDsn = function (deviceType) {
    return deviceType === DEVICE_TYPES.MOBILE
        ? 'https://55249fbac04c4eec85f716ce6ecd8007@sentry.wixpress.com/72'
        : 'https://31123ec476ce4702988dd34b68d01b9e@sentry.wixpress.com/68';
};
function getParams(_a) {
    var wixCodeApi = _a.wixCodeApi, platformAPIs = _a.platformAPIs, appParams = _a.appParams, compId = _a.compId;
    var deviceType = wixCodeApi.window.formFactor.toLowerCase();
    var biToken = getBiToken(appParams.instance, platformAPIs);
    var metaSiteId = platformAPIs.bi.metaSiteId;
    var viewMode = wixCodeApi.window.viewMode.toLowerCase();
    var instanceId = appParams.instanceId;
    var isLoggedIn = wixCodeApi.user.currentUser && wixCodeApi.user.currentUser.loggedIn;
    var userId = isLoggedIn ? wixCodeApi.user.currentUser.id : null;
    var renderingEnv = wixCodeApi.window.rendering.env;
    return {
        deviceType: deviceType,
        biToken: biToken,
        metaSiteId: metaSiteId,
        viewMode: viewMode,
        instanceId: instanceId,
        compId: compId,
        userId: userId,
        renderingEnv: renderingEnv,
    };
}
export function createAndSetupMonitor(createMonitor, controllerConfig) {
    var params = getParams(controllerConfig);
    var monitor = createMonitor(getDsn(params.deviceType));
    monitor.setEnvironment(__DEBUG__ ? 'development' : 'production');
    monitor.setRelease(__CI_APP_VERSION__);
    monitor.setUser({
        id: params.userId,
    });
    monitor.setTagsContext({
        ooif: __OOI__,
        deviceType: params.deviceType,
        viewMode: params.viewMode,
        renderingEnv: params.renderingEnv,
        msid: params.metaSiteId,
    });
    monitor.setExtraContext({
        biToken: params.biToken,
        instanceId: params.instanceId,
        msid: params.metaSiteId,
        compId: params.compId,
    });
    return monitor;
}
