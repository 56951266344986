import _ from 'lodash';
import { DEFAULT_LOCALE } from './config';
import parseQueryParams from 'shared/utils/get-query-params';
// Language priority (high to low):
// 1) Multilingual ('lang ') query param
// 2) Widget App Settings ('fonts.language')
// 3) Site language
// 4) Default locale
export var getAppLocale = function (_a) {
    var appSettings = _a.appSettings, siteLocale = _a.siteLocale;
    var queryParams = typeof window === 'undefined' ? {} : parseQueryParams(window.location.href);
    var appSettingsLocale = _.get(appSettings, 'fonts.language');
    return queryParams.lang || appSettingsLocale || siteLocale || DEFAULT_LOCALE;
};
